<template>
  <div
    style="height: inherit"
    :class="{ 'is-loading': pLoading }"
  >

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ meta.total }} {{ $t('catalog.list.labels.results') }}
              </div>
            </div>
            <div class="view-options">
              <b-button
                variant="primary"
                tag="a"
                class="d-flex align-content-center align-items-center justify-content-center"
                :to="{ name: 'admin:admin-projects-start', params: { id: 0 } }"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                {{ $t('project.list.btn.new') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-alert
      :show="!userData || !userData.telegram"
      variant="warning"
      class="p-1 mt-1"
      v-html="$t('info.bot.get')"
    />

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Prodcuts -->
    <section :class="adaptiveView">
      <b-card
        v-for="project in projects"
        :key="project.id"
        class="ecommerce-card"
        no-body
      >
        <!-- Product Details -->
        <b-card-body>
          <b-card-text class="item-date">
            {{ project.created }}
          </b-card-text>
          <h6 class="item-title">
            <b-link
              class="text-body"
              :to="{ name: 'admin:admin-projects-details', params: { id: project.id } }"
            >
              {{ project.title }}
            </b-link>
          </h6>
          <b-row>
            <b-col cols="6">
              <div class="d-inline-flex">
                <b-badge
                  variant="light-secondary"
                  class="mr-50"
                  style="padding: 10px"
                >
                  <feather-icon
                    size="10"
                    icon="ShoppingBagIcon"
                    class="mr-50"
                  />
                  {{ getProjectChannels(project) }}
                </b-badge>
                <b-badge
                  variant="light-secondary"
                  style="padding: 10px"
                >
                  <feather-icon
                    size="10"
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  {{ project.tasks.length }}
                </b-badge>
              </div>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-badge
                :variant="`light-${getColorByStatus(project.status)}`"
                style="padding: 10px"
              >
                <feather-icon
                  size="10"
                  :icon="getIconByStatus(project.status)"
                  class="mr-50"
                />
                {{ $t(`general.projectStatus.${project.status}`) }}
              </b-badge>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Product Info -->
        <b-card-body>
          <b-card
            no-body
            class="card-app-design mb-0"
          >
            <div class="prices">
              <b-row>
                <b-col cols="6">
                  <div class="price-item">
                    {{ getProjectSummPayed(project) }}
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <div class="price-item">
                    {{ getProjectSumm(project) }}
                  </div>
                </b-col>
              </b-row>
            </div>

            <div v-if="project.status === 'draft'">
              <b-button
                variant="outline-primary"
                tag="a"
                block
                :to="{ name: 'admin:admin-projects-start', params: { id: project.id } }"
              >
                <feather-icon
                  icon="UploadIcon"
                  class="mr-50"
                />
                {{ $t('project.list.btn.continue') }}
              </b-button>
            </div>
            <div v-else>
              <div class="transactions">
                <div
                  v-for="task in project.tasks"
                  :key="task.id"
                  class="transaction-item"
                  :class="`bg-${getColorByStatus(task.status)}`"
                  :style="`width: ${100 / project.tasks.length}%;`"
                />
              </div>

              <div class="statuses">
                <b-badge
                  v-for="status in statusTaskOptions"
                  :key="status.value"
                  v-b-tooltip.hover.v-primary
                  :variant="`light-${status.color}`"
                  style="padding: 10px"
                  :title="status.label"
                >
                  <feather-icon
                    size="10"
                    :icon="status.icon ? status.icon : 'BookmarkIcon'"
                    class="mr-50"
                  />
                  {{ getDataByStatus(project, status.value) }}
                </b-badge>
              </div>
            </div>

          </b-card>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="buttons">
            <b-button
              v-if="project.status !== 'draft'"
              variant="light"
              tag="a"
              :to="{ name: 'admin:admin-projects-details', params: { id: project.id } }"
            >
              <feather-icon
                icon="EyeIcon"
              />
            </b-button>
            <b-button
              v-if="['draft', 'completed', 'cancel'].includes(project.status)"
              variant="danger"
              @click="destroy(project.id)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <filter-sidebar
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :f-loading="fLoading"
      />
    </portal>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import _ from 'lodash'
import FilterSidebar from './FilterSidebar.vue'

export default {
  components: {
    FilterSidebar,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      mqShallShowLeftSidebar: false,
      fLoading: true,
      pLoading: true,
      projects: [],
      itemView: 'list-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      sortBy: { text: this.$i18n.t('catalog.list.sorting.id-desc'), value: 'id-desc' },
      sortByOptions: [
        { text: this.$i18n.t('catalog.list.sorting.id-desc'), value: 'id-desc' },
        { text: this.$i18n.t('catalog.list.sorting.price-asc'), value: 'prices_sum_price-asc' },
        { text: this.$i18n.t('catalog.list.sorting.price-desc'), value: 'prices_sum_price-desc' },
      ],
      filterOptions: [
        {
          label: this.$t('general.projectStatus.draft'), value: 'draft', color: 'secondary', icon: 'CircleIcon',
        },
        {
          label: this.$t('general.projectStatus.pending'), value: 'pending', color: 'secondary', icon: 'ClockIcon',
        },
        {
          label: this.$t('general.projectStatus.work'), value: 'work', color: 'info', icon: 'PlayCircleIcon',
        },
        {
          label: this.$t('general.projectStatus.cancel'), value: 'cancel', color: 'danger', icon: 'XCircleIcon',
        },
        {
          label: this.$t('general.projectStatus.completed'), value: 'completed', color: 'success', icon: 'CheckCircleIcon',
        },
        {
          label: this.$t('general.projectStatus.archive'), value: 'archive', color: 'secondary', icon: 'Trash2Icon',
        },
      ],
      statusTaskOptions: [
        {
          label: this.$t('general.taskStatus.pending'), value: 'pending', color: 'secondary', icon: 'ClockIcon',
        },
        {
          label: this.$t('general.taskStatus.work'), value: 'work', color: 'info', icon: 'PlayCircleIcon',
        },
        {
          label: this.$t('general.taskStatus.completed'), value: 'completed', color: 'success', icon: 'CheckCircleIcon',
        },
        {
          label: this.$t('general.taskStatus.review'), value: 'review', color: 'warning', icon: 'ThumbsUpIcon',
        },
        {
          label: this.$t('general.taskStatus.cancel'), value: 'cancel', color: 'danger', icon: 'XCircleIcon',
        },
      ],
      currentPage: 1,
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 12,
        to: 1,
        total: 1,
      },
      adaptiveView: window.outerWidth > 1200 ? 'list-view' : 'grid-view',
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.debFetchData(this)
      },
      deep: true,
      immediate: true,
    },
    currentPage: {
      handler(page) {
        const params = {
          ...this.$route.query,
          ...{ page },
        }
        this.$router.push({ name: 'admin:admin-projects-index', query: params })
      },
      deep: true,
      immediate: true,
    },
    sortBy: {
      handler(sb) {
        const params = {
          ...this.$route.query,
          ...{ sorting: sb.value },
        }
        this.$router.push({ name: 'admin:admin-projects-index', query: params })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.adaptiveView = window.outerWidth > 1200 ? 'list-view' : 'grid-view'
    },
    fetchData() {
      this.pLoading = true
      this.$http.get('/api/user/projects_from_admin/', {
        params: {
          sorting: this.sortBy.value,
          status: this.$route.query.status ?? null,
          per_page: this.meta.per_page,
          page: this.currentPage,
        },
      })
        .then(response => {
          this.projects = response.data.data
          this.meta = response.data.meta
          this.currentPage = response.data.meta.current_page
          this.pLoading = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    debFetchData: _.debounce(vm => {
      vm.fetchData()
    }, 350),
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/user/projects_from_admin/${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: this.$t('notifications.confirm.destroy.project'),
        text: null,
      })
    },
    getDataByStatus(item, status) {
      let total = 0
      item.tasks.forEach(i => { total += (i.status === status ? 1 : 0) })
      return total
    },
    getIconByStatus(status) {
      return _.filter(this.filterOptions, o => o.value === status)[0].icon
    },
    getColorByStatus(status) {
      const fS = _.filter(this.statusTaskOptions, o => o.value === status)
      return fS && fS[0] ? fS[0].color : 'secondary'
    },
    getProjectSumm(item) {
      let total = 0
      item.tasks.forEach(i => { total += i.price })
      return this.$options.filters.moneyFormat(total)
    },
    getProjectSummPayed(item) {
      let total = 0
      item.tasks.forEach(i => { total += (i.status === 'completed' ? i.price : 0) })
      return this.$options.filters.moneyFormat(total)
    },
    getProjectChannels(item) {
      const total = {}
      item.tasks.forEach(i => { total[i.product.id] = i.product.id })
      return Object.values(total).length
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.ecommerce-application {

  .grid-view {
    .ecommerce-card {
      .card-body {
        width: 100%;
      }

      .item-options {
        padding:1rem;

        .buttons {
          width: 100%;
          display: flex;
        }
      }
    }
  }

  .ecommerce-card {
    grid-template-columns: 5fr 3fr 1fr !important;
    overflow: visible !important;
    align-items: center;

    .item-date {
      display: block;
      font-size: 1em;
    }

    .item-title {
      display: block;
      font-size: 1.4em;
      padding: 0 0 10px;
      margin: 0 0 10px;
      border-bottom: 1px solid #ebe9f1;
    }

    .card-app-design {
      .prices {
        font-size: 1em;
        font-weight: bold;
        padding: 0 0 10px;
        margin: 0 0 10px;
        border-bottom: 1px solid #ebe9f1;
      }

      .statuses {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap:10px;
      }

      .transactions {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 0 20px;

        .transaction-item {
          height: 20px;
        }
      }
    }

    .item-options {
      padding:1rem;

      .buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }
  }
}

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
